.templateContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 2px solid #000;
  justify-content: center;
  padding: 10px;
  margin: 5px;
}

.templateImage {
  width: 175px;
  min-height: 235px;
}

.templateContainer:hover {
  cursor: pointer;
  border: 2px solid #00c8aa;
}

.templateName {
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-weight: 500;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #000;
  text-align: center;
}