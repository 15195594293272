/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

html, body, #root{
  /* height: auto;
  margin: 0 !important; 
  padding: 0 !important; */
  /* border: none !important; */
/* }

@media print {
  html, body {
     border: 0px solid red;
     /* height: 99%; */
     /* page-break-after: avoid;
     page-break-before: avoid;
     background-color: #fff;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html, body, #root {
  width: 100%;
  height: 100%;
  line-height: normal !important;
  background-color: #313B47;
}


@media print {
  html, body, #root {
    background-color: #ffffff;
  }
}