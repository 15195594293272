.container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  justify-content: center;
}

.layout {
  display: flex;
  flex: 1;
  border: 1px solid #00c8aa;
  margin-bottom: 10px;
  justify-content: center;
  padding-bottom: 50px;
}

.panel {
  min-width: 182px;
  min-height: 50px;
}

.availableSectionsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border: 1px solid #00c8aa;
  justify-content: center;
  width: fit-content;
  min-width: 182px;
  min-height: 50px;
}

.section {
  padding: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #f0f0f0;
  border: 1px solid #a0a0a0;
  margin: 5px;
  text-align: center;
  width: 150px;
}

.section:hover {
  color: #00c8aa;
}

.title {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #f0f0f0;
  margin-bottom: 5px;
}
