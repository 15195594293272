.editSection {
  padding-right: 5px;
  position: relative;
  box-sizing: border-box;
}

.regularSection {
  margin-bottom: 15px;
  display: block;
}

.listSection {
  margin-bottom: 0px;
  padding-right: 0px;
  display: inline-block;
}

.highlight {
  border-right: 1px dashed rgb(226, 75, 75);
}

.noHighlight {
  border: 0px;
}

.removeButtonContainer {
  /* display: flex;
  justify-content: flex-end; */
  position: absolute;
  bottom: 1px;
  right: 2px;
}