.sideDrawer {
  height: 100%;
  background: white;
  background: #313B47;
  position: fixed;
  top: 0 !important;
  right: 0 !important;
  /* width: 25%; */
  min-width: 100px;
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  border-left: 1px solid #000000;
  transform: translateX(100%);
  transition: transform 0.2s ease-out;
  overflow: scroll;
  /* zoom: 140%;
  transform: scale(1.4) !important;
  transform-origin: top right; */
}

.sideDrawer.open {
  transform: translateX(0);
}

.sideDrawer.myresumes {
  width: 295px;
}