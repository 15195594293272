.fontPickerContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.fontItem {
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: "Roboto", sans-serif;
  color: #f0f0f0;
  min-width: 150px;
  padding: 10px;
  margin: 5px;
  box-sizing: border-box;
  border: 2px solid #a0a0a0;
}

.fontItem:hover {
  cursor: pointer;
  border: 2px solid #00c8aa;
}

.title {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #f0f0f0;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-top: 5px;
}