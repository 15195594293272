.lineContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.plusButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #00c8aa;
  margin-right: 10px;
}

.ultrabig {
  font-weight: 300;
  font-size: 40px;
  width: 40px;
  height: 40px;
}

.big {
  font-weight: 300;
  font-size: 20px;
  width: 20px;
  height: 20px;
}

.small {
  font-weight: 300;
  font-size: 15px;
  width: 15px;
  height: 15px;
}

.plusButton > span {
  color: #fff;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: "Roboto", sans-serif;
}

.ultrabig:hover {
  font-size: 50px;
}

.big:hover {
  font-size: 23px;
}

.small:hover {
  font-size: 17px;
}

.plusButton > span:hover {
  cursor: pointer;
}

.line {
  height: 0px;
  border-bottom: 1px dashed #00c8aa;
  flex: 1;
}