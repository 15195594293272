.container {
  display: flex;
  flex-direction: column;
  transform: scale(1);
  transform-origin: center top;
  margin: 0 !important; 
  padding: 0 !important;
  width: fit-content;
}

@media print {
  .container {
    transform: scale(1.0);
    margin: 0 !important; 
    padding: 0 !important;
  }
}

.noEdit {
  pointer-events: none;
}

