.inputField {
  padding: 1px;
  margin: 1px;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  position: relative;
  min-height: 0px !important;
  word-break: break-word;
}

.inputField p {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}

.inputField a {
  color: inherit;
}

.inputField:focus {
  outline: 0 solid transparent;
  border-bottom: 1px solid #00c8aa;
}

.inputField[placeholder]:empty:before {
  content: attr(placeholder) !important;
  /* position: absolute; */
  color: rgb(156, 161, 157);
  background-color: transparent;
}

.inputField[placeholder]:after {
  content: '' !important;
}

.editMode {
  border-bottom: 1px dashed gray;
}

.viewMode {
  border-bottom: none;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.capitalCase {
  text-transform: uppercase;
}

.anyCase {
  text-transform: none;
}

.fontFamilyRoboto {
  font-family: "Roboto", sans-serif;
}

.fontWeight300 {
  font-weight: 300;
}

.fontWeight400 {
  font-weight: 400;
}

.fontWeight500 {
  font-weight: 500;
}

.fontWeight700 {
  font-weight: 700;
}

.fontSize10 {
  font-size: 10px;
}

.fontSize15 {
  font-size: 15px;
}

.fontSize18 {
  font-size: 18px;
}

.fontSize20 {
  font-size: 20px;
}

.fontSize25 {
  font-size: 25px;
}

.fontSize30 {
  font-size: 30px;
}

.fontSize35 {
  font-size: 35px;
}

.fontSize40 {
  font-size: 40px;
}

.fontSize45 {
  font-size: 45px;
}

.fontSize50 {
  font-size: 50px;
}
