.anchor {
  text-decoration: none;
}

.blogItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: start;
  border: 2px solid #00c8aaaa;
  padding: 10px;
  background-color: #fff;
}

.blogItemContainer:hover {
  border: 2px solid #00c8aa;
}

.blogItemImage {
  width: 200px;
}

.blogItemTitle {
  color: #000;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: "Roboto", sans-serif;
  margin-left: 20px;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none !important;
}

.blogItemDescription {
  color: #555;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: "Roboto", sans-serif;
  margin-left: 20px;
  font-size: 15px;
  text-decoration: none;
}

.blogItemRightContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

