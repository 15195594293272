.editBarContainer {
  background-color: #425061;
  box-shadow: 0px 0px 2px #000;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 0px;
}

.editItem {
  padding: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #fff;
}

.editItem:hover {
  color: #00c8aa;
  cursor: pointer;
}

.editWholeContainer {
  width: fit-content;
}
