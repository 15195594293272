.formInputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.formInput {
  box-sizing: border-box;
  width: 300px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #474f58;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  outline: none !important;
}

.formInput:focus {
  border: 1px solid #00c8aa;
}

.formInputError {
  border: 1px solid rgb(207, 95, 95);
}

.errorMessage {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: rgb(207, 95, 95);
}

.centered {
  text-align: center;
}