.containerTheme1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.firstBoxTheme1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 400px;
}

.secondBoxTheme1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 200px;
}




.containerTheme2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.firstBoxTheme2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 400px;
  margin-bottom: 10px;
}

.secondBoxTheme2 {
  display: flex;
  flex-direction: row;
  justify-content: left;
  min-width: 200px;
}

.secondBoxTheme2 > div {
  margin-right: 15px;
}



.containerTheme3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.firstBoxTheme3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 400px;
  margin-bottom: 10px;
  width: fit-content;
}

.firstBoxTheme3 > div {
  text-align: center;
}

.secondBoxTheme3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 200px;
}

.secondBoxTheme3 > div {
  margin-right: 15px;
}