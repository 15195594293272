.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.active {
  opacity: 1;
  width: 100%;
  height: 100%;
}

.faded {
  opacity: 0.4;
  pointer-events: none;
}

.slider{
  position:absolute;
  top: 0;
  width:100%;
  height:5px;
  overflow-x: hidden;
}

.line{
  position:absolute;
  opacity: 0.4;
  background:#00c8aa;
  width:150%;
  height:5px;
}

.subline{
  position:absolute;
  background:#00c8aa;
  height:5px; 
}

.inc{
animation: increase 2s infinite;
}

.dec{
animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
 from { left: -5%; width: 5%; }
 to { left: 130%; width: 100%;}
}
@keyframes decrease {
 from { left: -80%; width: 80%; }
 to { left: 110%; width: 10%;}
}

.messageContainer{
  position:absolute;
  top: 5px;
  width:100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.message {
  color: #ffffff;
  width: fit-content;
  min-width: 250px;
  background-color: #d7f6f2;
  color: #00c8aa;
  border-radius: 5px;
  text-align: center;
  padding: 15px 30px;
  z-index: 999;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
}

.messageSuccess {
  background-color: #d7f6f2;
  color: #00c8aa;
}

.messageFailure {
  background-color: rgb(248, 202, 202);;
  color: rgb(226, 75, 75);;
}
