.remove {
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: "Roboto", sans-serif;
  font-size: 8px;
  font-weight: 300;
  color: rgb(226, 75, 75);
  /* background-color: #ffffff; */
  width: fit-content;
  /* padding: 2px 2px; */
}

.remove:hover {
  cursor: pointer;
}

.show {
  display: block;
}

.hide {
  display: none;
}