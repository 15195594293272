.background {
  background-color: #313B47;
}

.editorWrapper {
  display: flex;
  justify-content: center;
  margin: 0 !important; 
  padding: 0 !important;
  border: none !important;
  /* height: auto; */
  position: relative;
}

.editorContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.mainSection {
  display: flex;
  flex-direction: column;
  transform: scale(1.4);
  transform-origin: center top;
  /* zoom: 140%;
  -moz-transform: scale(1.4);
  -moz-transform-origin: center top; */
}

@media print {
  .mainSection {
    zoom: 200% !important;
    transform: scale(1);
    transform-origin: top;
  }
}

.logo {
  font-family: "Roboto", sans-serif;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #ffffff;
  font-size: 18px;
  margin-top: 15px;
  margin-left: 30px;
  width: fit-content;
  /* position: absolute;
  top: 10px;
  left: 20px;
  z-index: 2; */
}

.logo:hover {
  color: #00c8aa;
  cursor: pointer;
}

.logo a {
  text-decoration: none;
  color: inherit;
}

.topToolsPanel {
  margin-top: 10px;
  margin-bottom: 10px;
}

.display {
}

.editBarsContainer {
  display: flex;
  justify-content: space-between;
  /* width: 1111px; */
}
