.authenticateScreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.authenticateBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(48, 49, 48, 0.75);
  z-index: 10;
}

.authenticateContainer {
  box-shadow: 0px 0px 2px #000;
  border-radius: 20px;
  width: fit-content;
  position: fixed;
  display: flex;
  flex-direction: row;
  z-index: 100;
  background-color: white;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #000;
  padding: 20px;
  min-height: 350px;
  justify-content: center;
  align-items: center;
}

.leftPanel {
  max-width: 400px;
  border: 1px solid #000;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  font-family: "Roboto", sans-serif;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #000000;
  text-align: center;
}

.rightPanel {
  display: flex;
  flex-direction: column;
}

.optionChooserContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.option {
  padding: 10px;
  margin: 10px;
}

.option:hover {
  cursor: pointer;
  color: #00c8aa;
}

.optionSelected {
  color: #00c8aa;
}

.optionBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.orText {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  margin: 10px;
}

.googleButtonContainer {
  width: 100%;
}

.googleButtonContainer > button {
  width: 100%;
  justify-content: center;
  border-radius: 10px !important;
  border: 1px solid #e0e0e0 !important;
  color: #000000 !important;
}

.centeredDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.registerCodePrompt {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  margin-bottom: 10px;
  text-align: center;
}

.registerCodePromptEmail {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  margin-bottom: 40px;
  text-align: center;
}

.registerCodeInputWrapper {
  margin-bottom: 20px;
}

.forgotPassword {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  text-align: center;
  padding: 15px 0px;
}

.forgotPassword:hover {
  cursor: pointer;
  color: #00c8aa;
}

.disclaimer {
  width: 300px;
  text-align: center;
  font-size: 12px;
  margin-top: 15px;
  color: #505050;
}