.App {
  /* display: flex;
  justify-content: center;
  margin: 0 !important; 
  padding: 0 !important;
  border: none !important;
  height: auto;
  position: relative; */
  width: 100%;
  height: 100%;
}

.page:last-child {
  page-break-after: auto;
}