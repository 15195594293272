.section {
  width: auto;
  height: auto;
  margin: 0px 0px 20px 0px;
  padding: 0px;
  border: 0px solid #b1c2fb;
  position: relative;
  box-sizing: border-box;
}

.titleContainer {
  margin-bottom: 5px;
}

.highlight {
  border: 1px dotted rgb(226, 75, 75);
}

.noHighlight {
  border: 0px;
}