.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 25px;
}

.logo {
  font-family: "Roboto", sans-serif;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #00c8aa;
}