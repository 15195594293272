.container {
  width: 100%;
  justify-content: center;
  position: relative;
  height: fit-content;
}

.myresumesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: scale(0.35);
  transform-origin: top left;
  width: fit-content;
  /* zoom: 0.3; */
  /* width: 500px; */
}

.newButton {
  color: #000;
  position: fixed;
  left: 0;
  bottom: 0;
}

.newButton:hover {
  color: #00c8aa;
}

.addContainer {
  padding: 5px;
}