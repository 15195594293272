.colourPickerContainer {
  width: 245px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.colourItem {
  width: 35px;
  height: 35px;
  box-sizing: border-box;
}

.colourItem:hover {
  cursor: pointer;
  border: 2px solid #00c8aa;
}