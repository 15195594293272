.page {
  /* width: 31.5cm;
  height: 44.55cm; */
  width: 210mm;
  height: 296mm;
  border: 0px solid #a1d1d3;
  background-color: #ffffff;
  page-break-after: always;
  -webkit-text-size-adjust: 100%;
  display: flex;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 10px;
}

@media print {
  .page {
    /* width: 21cm;
    height: 29.7cm; */
    margin: 0 !important; 
    padding: 0 !important;
    page-break-after: auto;
    margin-bottom: none;
    overflow: hidden;
  }
}

.marginWrapper {
  margin: 40px;
  border: 0px solid green;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.body {
  display: flex;
  border: 0px solid blue;
  box-sizing: border-box;
  overflow: auto;
  flex: 1;
}

.header {
  border: 0px solid red;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.headerDivider {
  border-bottom: 2px solid #000;
}