.formButton {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  padding: 10px 20px;
  margin-bottom: 8px;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}

.formButton:hover {
  cursor: pointer;
}

.theme1 {
  background-color: #00c8aa;
  color: #ffffff;
}

.theme2 {
  background-color: #abe2da;
  color: #000000;
}