.panel {
  border: 0px solid #daa0a0;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.noRightPad {
  padding-right: 0px;
}

.rightPad {
  padding-right: 30px;
}

.highlight {
  border: 1px solid red;
}

.noHighlight {
  border: none;
}