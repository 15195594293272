.resumeDisplayContainer {
  margin: 20px;
  width: fit-content;
  border: 5px solid #000; 
  position: relative;
  background: #ffffff;
}

.resumeDisplayContainer:hover {
  cursor: pointer;
  border: 5px solid #00c8aa;
}

.deleteContainer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 30px;
  right: 20px;
}

.deleteButton {
  color: #000;
}

.deleteButton:hover {
  color: rgb(226, 75, 75);
}

.deleteOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(179, 110, 110, 0.664);
  font-family: "Roboto", sans-serif;
  font-size: 50px;
  font-weight: 500;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.deleteOverlay:hover {
  cursor: default;
}

.deleteConfirmContainer {

}

.deleteConfirm {
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.deleteConfirmYes {
  color: #ffffff;
}

.deleteConfirmNo {
  color: #ffffff;
  margin-right: 50px;
}

.deleteConfirmYes:hover {
  cursor: pointer;
  color: rgb(32, 30, 30);
}

.deleteConfirmNo:hover {
  cursor: pointer;
  color: rgb(19, 20, 19);
}