.block {
  background-color: rgb(202, 241, 202);
  border-right: 1px solid rgb(170, 164, 164);
}

.block:hover {
  cursor: pointer;
  border-right: 2px solid white;
}

.block_invalid {
  background-color: rgb(202, 241, 202);
  border-right: none;
  pointer-events: none;
}